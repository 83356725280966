import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import Spin from "antd/lib/spin";

import { selectAllStatistics } from "../../store/statistics/statistics.selectors";
import { MapFooterColumn, MapFooterContainer, MapFooterSourceInformation } from "../Map/Map.styled";
import MapLegendGradient from "./MapLegendGradient";
import MapLegend from "./MapLegend";
import Disclaimer from "./Disclaimer";

type MapFooterProps = {
  region?: string;
};

const MapFooter: React.FC<MapFooterProps> = ({ region }) => {
  const statistics = useSelector(selectAllStatistics);

  const graduatesCount = useMemo(() => {
    return statistics
      .filter((item) => (region ? item.res_code === region : true))
      .reduce((acc, item) => acc + item.result_graduates, 0);
  }, [region, statistics]);

  if (graduatesCount === 0) return null;

  return (
    <MapFooterContainer>
      <MapFooterColumn>
        <MapFooterSourceInformation>
          Источники данных: <br />{" "}
          <b>HeadHunter, SuperJob, Работа в России, Министерство Образования</b>
        </MapFooterSourceInformation>
      </MapFooterColumn>
      <MapFooterColumn>
        <React.Suspense fallback={<Spin />}>
          <Disclaimer />
        </React.Suspense>
      </MapFooterColumn>
      <MapFooterColumn>
        <MapLegendGradient />
        <MapLegend vacanciesCount={graduatesCount} />
      </MapFooterColumn>
    </MapFooterContainer>
  );
};

export default React.memo(MapFooter);
