import axios from "axios";
import { ISpecialization } from "../store/specializations/specializations.reducer";

const fetch = async (mode?: string) => {
  try {
    const response = await axios.get<ISpecialization[]>("/specializations", { params: { mode } });
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    throw new Error("Ошибка при загрузке");
  }
};

export const SpecializationAPI = { fetch };
