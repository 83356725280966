import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StatisticAPI } from "../../services/statistics.service";
import { IOptions } from "../../types/NetworkState";
import { loading } from "../app/app.reducer";

export type StatisticDataType = {
  res_id: number;
  res_name: string;
  res_code: string;
  result_graduates: number;
  result_vacancies: number;
  result_accepted: number;
  result_exp_graduates: number;
  result_study: number;
};

export interface IStatistics {
  country: StatisticDataType[];
  region: StatisticDataType[];
}

const initialState: StatisticDataType[] = [];

export const fetchStatistics = createAsyncThunk(
  "statistics/fetchCountryStatistics",
  async (options: IOptions, { dispatch }) => {
    try {
      dispatch(loading(true));
      const statistics = await StatisticAPI.fetch(options.filters, options.params);
      return statistics;
    } catch (error: any) {
      console.log(`error.message`, error.message);
      return [];
    } finally {
      dispatch(loading(false));
    }
  }
);

const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStatistics.fulfilled, (_, action) => action.payload);
  },
});

const { reducer } = statisticsSlice;

export default reducer;
