import { Header } from "antd/lib/layout/layout";
import styled from "styled-components";
import { device } from "../../util/mediaQueries";

export const HeaderWrapper = styled(Header)`
  background-color: #fff;
  height: auto;
  padding: 0 15px 8px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: normal;
  @media ${device.laptopL} {
    flex-wrap: nowrap;
  }
`;

export const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  flex-basis: 100%;
  @media ${device.tablet} {
    flex-basis: 30%;
  }
  @media ${device.laptopL} {
    margin: auto;
    flex-basis: 50%;
  }
`;

export const HeaderTextLogo = styled.div`
  @media ${device.tablet} {
    margin-right: 1rem;
    margin-top: 0.5rem;
  }
`;

export const HeaderSwitch = styled.div``;

export const HeaderTextLogoName = styled.div`
  font-size: 21px;
  line-height: 1;
  margin-bottom: 6px;
  text-transform: uppercase;
  b {
    color: #1585da;
    font-weight: normal;
  }
  sup {
    color: rgba(0, 0, 0, 0.3);
    font-size: 0.9rem;
    margin-left: 4px;
  }
  @media ${device.laptop} {
    font-size: 24px;
  }
`;

export const HeaderTextLogoDescription = styled.div`
  color: #696969;
  font-size: 12px;
  line-height: 14px;
  @media ${device.laptop} {
    font-size: 14px;
    line-height: 18px;
    max-width: 320px;
  }
`;

export const HeaderImageLogo = styled.div``;

export const HeaderBanners = styled.div`
  order: -1;
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  text-align: right;

  @media ${device.tablet} {
    order: 0;
    display: flex;
    justify-content: flex-end;
    flex-basis: 56%;
  }

  @media ${device.laptop} {
    flex-basis: 65%;
  }

  @media ${device.laptopM} {
    flex-basis: 69%;
  }

  @media ${device.laptopL} {
    flex-basis: 30%;
  }

  img {
    margin: 10px 0 10px 10px;
    height: 30px;
    @media ${device.tablet} {
      height: 30px;
      margin: 1rem 0 0 10px;
    }
  }
`;
