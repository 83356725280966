import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { fetchStatistics } from "../../store/statistics/statistics.reducer";
import { selectLevelsOptions } from "../../store/levels/levels.selector";
import { fetchLevels } from "../../store/levels/levels.reducer";
import { selectSpecializationsOptions } from "../../store/specializations/specializations.selector";
import { fetchSpecialization } from "../../store/specializations/specializations.reducer";
import { selectFormatsOptions } from "../../store/formats/formats.selector";
import { fetchFormats } from "../../store/formats/formats.reducer";

import { useFilters } from "../../hooks/filters.hooks";
import { filterKey } from "../../util/filterKeys";
import { FilterWrapper } from "./Filter.styled";
import FilterItem from "./FilterItem";

function Filters() {
  const dispatch = useDispatch();
  const [{ filter }, setFilter] = useFilters();

  // Обновляем статистику по стране
  useEffect(() => {
    dispatch(fetchStatistics({ filters: filter }));
  }, [dispatch, filter]);

  const handleChange = useCallback(
    (key: string, value: number) => {
      setFilter(key, value);
      // Сбрасываем фильтр специализации при изменении уровня
      if (key === filterKey.level) {
        setFilter(filterKey.specialization, undefined);
      }
    },
    [setFilter]
  );

  return (
    <FilterWrapper>
      <FilterItem
        filterKey={filterKey.level}
        value={filter.education_level}
        placeholder="Уровень образования"
        onChange={handleChange}
        selector={selectLevelsOptions}
        action={fetchLevels}
      />
      <FilterItem
        filterKey={filterKey.specialization}
        value={filter.specialization_code}
        placeholder="Специализация"
        onChange={handleChange}
        selector={selectSpecializationsOptions(+filter.education_level)}
        action={fetchSpecialization}
      />
      <FilterItem
        filterKey={filterKey.education}
        value={filter.education_type}
        placeholder="Форма обучения"
        onChange={handleChange}
        selector={selectFormatsOptions}
        action={fetchFormats}
      />
    </FilterWrapper>
  );
}

export default React.memo(Filters);
