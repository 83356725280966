import React, { useCallback } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useSelector } from "react-redux";

import { useRegion } from "../../hooks/region.hooks";
import { useFilters } from "../../hooks/filters.hooks";
import { selectAllStatistics } from "../../store/statistics/statistics.selectors";
import { StatisticDataType } from "../../store/statistics/statistics.reducer";
import {
  selectRatingForms,
  selectRatingLevels,
  selectRatingSpecialization,
} from "../../store/rating/rating.selectors";

import { ChartBar } from "../Charts/types";
import SidebarTitle from "./SidebarTitle";
import RegularChart from "../Charts/RegularChart";
import AsyncChart from "../Charts/AsyncChart";
import {
  fetchRatingByForms,
  fetchRatingByLevels,
  fetchRatingBySpecialization,
} from "../../store/rating/rating.reducer";
import { filterKey } from "../../util/filterKeys";

type RouteParams = {
  region: string;
};

const Sidebar: React.FC<RouteComponentProps<RouteParams>> = ({ history }) => {
  const statistics = useSelector(selectAllStatistics);
  const [{ search, filter }, setFilter] = useFilters();
  const region = useRegion();

  const handleBackToCountry = useCallback(() => {
    const queryString = search.toString();
    // Перемещаемся на главную, с сохранением фильтров
    if (queryString) {
      history.push(`/?${queryString}`);
    } else {
      history.push(`/`);
    }
  }, [history, search]);

  const handleSelect = useCallback(
    (bar: StatisticDataType) => {
      if (!bar.res_code) return;
      const queryString = search.toString();
      if (queryString) {
        history.push(`/${bar.res_code}?${queryString}`);
      } else {
        history.push(`/${bar.res_code}`);
      }
    },
    [history, search]
  );

  const handleSelectBySlice = useCallback(
    (bar: StatisticDataType, slice: string) => {
      setFilter(filterKey[slice], bar.res_code);
    },
    [setFilter]
  );

  const bars: ChartBar[] = [
    {
      name: "Количество выпускников",
      key: "result_graduates",
      color: "#1585da",
    },
    {
      name: "Количество поступивших",
      key: "result_accepted",
      color: "#26e7a6",
    },
  ];

  const filteredStatistics = statistics.filter((item) =>
    region ? item.res_code === region.r_code : true
  );

  const fetchLevels = useCallback(
    () =>
      fetchRatingByLevels({
        filters: filter,
        params: { region_id: region?.r_code },
      }),
    [region?.r_code, filter]
  );

  const fetchFormats = useCallback(
    () =>
      fetchRatingByForms({
        filters: filter,
        params: { region_id: region?.r_code },
      }),
    [region?.r_code, filter]
  );

  const fetchSpecializations = useCallback(
    () =>
      fetchRatingBySpecialization({
        filters: filter,
        params: { region_id: region?.r_code },
      }),
    [region?.r_code, filter]
  );

  return (
    <>
      <SidebarTitle region={region} onBackToCountry={handleBackToCountry} />
      <RegularChart
        data={filteredStatistics}
        onClick={handleSelect}
        bars={bars}
        title="Выпуск/Набор"
      />
      {/* <AsyncChart
        selector={selectRatingSpecialization}
        action={fetchSpecializations}
        bars={bars}
        onClick={(bar) => handleSelectBySlice(bar, "specialization")}
        title={`Срез по уровню специализации`}
      /> */}

      <AsyncChart
        selector={selectRatingLevels}
        action={fetchLevels}
        bars={bars}
        onClick={(bar) => handleSelectBySlice(bar, "level")}
        title={`Срез по уровню обучения`}
      />

      <AsyncChart
        selector={selectRatingForms}
        action={fetchFormats}
        bars={bars}
        onClick={(bar) => handleSelectBySlice(bar, "education")}
        title={`Срез по формату образования`}
      />
    </>
  );
};

export default React.memo(Sidebar);
