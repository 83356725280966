import React from "react";
import Menu from "antd/lib/menu";
import Dropdown from "antd/lib/dropdown";
import Button from "antd/lib/button";
import EllipsisOutlined from "@ant-design/icons/lib/icons/EllipsisOutlined";
import styled from "styled-components";

import Filters from "../Filter/Filters";

import LogoPNG from "../../assets/logo.png";
import ZkLogo from "../../assets/zk-logo.png";

import {
  HeaderBanners,
  HeaderLogo,
  HeaderSwitch,
  HeaderTextLogo,
  HeaderTextLogoName,
  HeaderWrapper,
} from "./Header.styled";

import { device } from "../../util/mediaQueries";

const SButton = styled(Button)`
  font-weight: 500;
  margin-right: 5px;
  span:last-child {
    display: none;
    @media ${device.tablet} {
      display: inline-block;
    }
  }
`;

const isTest =
  window.location.hostname.startsWith("test.") || process.env.NODE_ENV === "development";

const menu = (
  <Menu style={{ maxWidth: 200 }}>
    <Menu.Item key="vacancy">
      <a
        rel="noopener noreferrer"
        href={isTest ? "https://test.bmk-map.2035.university" : "https://bmk-map.2035.university"}
      >
        Вакансии
      </a>
    </Menu.Item>
    <Menu.Item key="hr">
      <a
        rel="noopener noreferrer"
        href={
          isTest
            ? "https://test.junior.bmk-map.2035.university"
            : "https://junior.bmk-map.2035.university"
        }
      >
        Кадры
      </a>
    </Menu.Item>
    <Menu.Item>
      <a
        rel="noopener noreferrer"
        href={
          isTest
            ? "https://test.trends.bmk-map.2035.university"
            : "https://trends.bmk-map.2035.university"
        }
      >
        Тренды
      </a>
    </Menu.Item>
  </Menu>
);

const Header = () => {
  return (
    <HeaderWrapper>
      <HeaderLogo>
        <HeaderTextLogo>
          <HeaderTextLogoName title="Сервис аналитики рынка труда в сфере ИТ (вакансии, компетенции)">
            БМКарта<b>Кадры</b>
            <sup>
              <i>beta</i>
            </sup>
          </HeaderTextLogoName>
        </HeaderTextLogo>
        <HeaderSwitch>
          <Dropdown overlay={menu} trigger={["click", "hover"]}>
            <SButton type="ghost" shape="round" icon={<EllipsisOutlined />}>
              Сервисы
            </SButton>
          </Dropdown>
        </HeaderSwitch>
      </HeaderLogo>
      <Filters />
      <HeaderBanners>
        <img src={ZkLogo} alt="" height={36} />
        <img src={LogoPNG} alt="" height={36} />
      </HeaderBanners>
    </HeaderWrapper>
  );
};

export default React.memo(Header);
