import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "..";

export const selectRegions = (state: RootState) => state.regions;

export const regionsOptions = createSelector(selectRegions, (state) =>
  state.entities
    .map((region) => ({ label: region.r_name, value: region.r_code }))
    .sort((a, b) => a.label.localeCompare(b.label, "ru"))
);

export const selectRegionByCode = (region: string) =>
  createSelector(selectRegions, (state) => state.entities.find((item) => item.r_code === region));
